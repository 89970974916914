<template>
  <router-view/>
</template>

<style>
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  * {
    font-family: "HanHei SC",PingHei,"PingFang SC","Helvetica Neue",Helvetica,Arial,"Microsoft Yahei","Hiragino Sans GB","Heiti SC","WenQuanYi Micro Hei",sans-serif;;
    font-style: normal;
    font-weight: 400;
  }

  #app {
    height: 100%;
  }
</style>
